import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import ListColumnsContainer from "@src/components/core-list-columns"
import InputDropdown from "@src/components/input-dropdown"
import Button from "@src/components/core-button"
import Link from "@src/components/core-link"
import { BASE_PATH_MAP } from "@src/utils/constants"
const Actions = styled.div`
  display: none;
  text-align: center;

  @media only screen and (${props => props.theme.screen.small.min}) {
    display: block;
    padding-top: 20px;
  }
`
const TabLink = styled(Link)`
  &:not(:last-child) {
    margin-right: 10px;
  }
`

const Activity = styled(Link)`
  background-color: ${props => props.theme.primary};
  text-align: center;
  color: ${props => props.theme.white};
  border-radius: 5px;
  font-weight: bold;
  &:hover {
    color: ${props => props.theme.white} !important;
  }
`

export const getActivityTypeRouteCount = (
  activityTypeDetails,
  activityTypeId
) => {
  if (activityTypeId) {
    for (const activityTypeDetail of activityTypeDetails) {
      if (
        activityTypeDetail.activityType &&
        activityTypeDetail.activityType?.id === activityTypeId
      ) {
        return activityTypeDetail.routeCount
      }
    }
  } else {
    return activityTypeDetails.reduce(
      (routeCount, activityTypeDetail) =>
        routeCount + (activityTypeDetail.routeCount || 0),
      0
    )
  }
  return null
}
export const getRegionLabel = (region, activityTypeId) => {
  let regionLabel = (region && region.name) || ""
  if (region && region.activityTypeDetails?.nodes) {
    const routeCount = getActivityTypeRouteCount(
      region.activityTypeDetails.nodes,
      activityTypeId
    )
    if (routeCount !== null) {
      regionLabel += ` (${routeCount})`
    }
  }
  return regionLabel
}

const getActivityLabel = activity => {
  return (
    (activity && activity.activityType && activity.activityType?.name) +
    ` (${activity.routeCount || 0})`
  )
}

const QuickBrowse = ({
  dropdown,
  tabs,
  list,
  button,
  gps,
  mapPath,
  regions,
  activityType,
  activityTypes,
  className,
}) => {
  const [activeRegion, setActiveRegion] = useState()
  const [activeActivity, setActiveActivity] = useState(
    activityType && activityType.id
  )
  const [activities, setActivities] = useState()
  const [childRegions, setChildRegions] = useState()

  // handle activeRegion change
  useEffect(() => {
    if (activeRegion && activeRegion.children?.nodes) {
      setChildRegions(activeRegion.children?.nodes)
    } else {
      setChildRegions(regions)
    }
  }, [activeRegion, regions, activeActivity])

  // handle childRegions change
  useEffect(() => {
    if (childRegions && childRegions.length) {
      // read activities from childRegions
      const childActivities = childRegions.reduce((result, region) => {
        if (region.activityTypeDetails?.nodes?.length) {
          for (const activityTypeDetail of region.activityTypeDetails.nodes) {
            if (
              activityTypeDetail.activityType &&
              activityTypeDetail.activityType?.id &&
              !result.some(
                check => check.id === activityTypeDetail.activityType.id
              )
            ) {
              result.push(activityTypeDetail.activityType)
            }
          }
        }
        return result
      }, [])
      setActivities(childActivities)
      if (
        activeActivity &&
        !childActivities.some(check => check.id === activeActivity)
      ) {
        setActiveActivity()
      }
    }
  }, [childRegions, activeActivity])

  // render a link for a region
  const getRegionLink = (region, activityTypeId) => {
    // for drill-down, activityType must be selected and at least one child must have it
    if (
      (activityTypeId || (dropdown && !activeRegion)) &&
      region.children?.nodes?.some(
        check =>
          !activityTypeId ||
          (check.activityTypeDetails &&
            check.activityTypeDetails?.nodes?.some(
              checkDetail =>
                checkDetail.activityType &&
                checkDetail.activityType?.id === activityTypeId
            ))
      )
    ) {
      return (
        <Link
          onClick={event => {
            event.preventDefault()
            setActiveRegion(region)
            return false
          }}
          variant="compact"
        >
          {getRegionLabel(region, activityTypeId)}
        </Link>
      )
    }

    const activityTypeDetail =
      activityTypeId &&
      region &&
      region.activityTypeDetails?.nodes?.find(
        check => check.activityType && check.activityType.id === activityTypeId
      )

    // use the URL from the region's activityTypeDetails, primaryActivityPage (hikes/region), or default to the region's base URL (what-to-do)
    const regionUrl =
      (activityTypeDetail && activityTypeDetail.uri) ||
      (region.activityTypePrimary && region.activityTypePrimary.uri) ||
      region.uri

    return (
      <Link to={regionUrl} variant="compact">
        {getRegionLabel(region, activityTypeId)}
      </Link>
    )
  }

  const getActivityLink = activity => {
    const activityUrl = activity.uri
    return (
      <Activity to={activityUrl} variant="compact">
        {getActivityLabel(activity)}
      </Activity>
    )
  }

  // render the dropdown
  const Dropdown = dropdown && (
    <div>
      <InputDropdown
        onChange={({ region }) => {
          setActiveRegion(region)
        }}
        value={activeRegion || null}
        options={regions.map(region => ({
          text: region.name,
          value: `${region.id}`,
          region,
        }))}
      />
    </div>
  )

  // render the tabs
  const Tabs = tabs && (
    <div>
      <TabLink
        key={0}
        onClick={event => {
          event.preventDefault()
          setActiveActivity()
          setActiveRegion()
          return false
        }}
        disabled={!activeActivity}
        variant="fancy"
        className={!activeActivity ? "active" : ""}
      >
        All
      </TabLink>
      {activities &&
        activities.length &&
        activities.map(activity => (
          <TabLink
            key={activity.id}
            onClick={event => {
              event.preventDefault()
              setActiveActivity(activity.id)
              return false
            }}
            disabled={activeActivity === activity.id}
            variant="fancy"
            className={activeActivity === activity.id ? "active" : ""}
          >
            {activity.name}
          </TabLink>
        ))}
    </div>
  )

  let Items
  if (list === "activities") {
    Items = activityTypes && (
      <ListColumnsContainer
        items={activityTypes.reduce((items, activity) => {
          if (activity && activity.activityType && activity.activityType?.id) {
            items.push({
              id: activity.activityType.id,
              title: activity.activityType.name,
              content: getActivityLink(activity),
            })
          }
          return items
        }, [])}
      />
    )
  } else {
    // render the list of items
    // choose the regions to render (default to top-level regions when no region is active)
    const showRegions = activeRegion ? childRegions : regions
    Items = showRegions && (
      <ListColumnsContainer
        items={showRegions.reduce((items, region) => {
          if (
            !activeActivity ||
            region.activityTypeDetails?.nodes?.some(
              check =>
                check.activityType && check.activityType.id === activeActivity
            )
          ) {
            const routeCount = getActivityTypeRouteCount(
              region.activityTypeDetails?.nodes || [],
              activeActivity
            )
            if (routeCount) {
              items.push({
                id: region.id,
                title: region.name,
                content: getRegionLink(region, activeActivity),
              })
            }
          }
          return items
        }, [])}
      />
    )
  }

  // render the button
  const CTAButton =
    button === true ? (
      <Button
        to={`${BASE_PATH_MAP}${mapPath ? `${mapPath}/` : ""}`}
        gps={gps}
        rel="nofollow"
        icon="map"
        variant="rounded"
        size="large"
      >
        View Map
      </Button>
    ) : (
      button
    )

  // list == activities used for "other activities in region"
  return (
    <div className={className}>
      {Dropdown}
      {Tabs}
      {Items}
      {CTAButton && <Actions>{CTAButton}</Actions>}
    </div>
  )
}
QuickBrowse.propTypes = {
  title: PropTypes.string,
  button: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
  gps: PropTypes.shape({
    lat: PropTypes.number,
    lon: PropTypes.number,
  }),
  mapPath: PropTypes.string,
  dropdown: PropTypes.oneOf([undefined, false, true, "regions"]),
  tabs: PropTypes.oneOf([undefined, false, true, "activities"]),
  list: PropTypes.oneOf(["regions", "activities"]),
  regions: PropTypes.array,
  activityType: PropTypes.shape({
    id: PropTypes.number,
    uri: PropTypes.string,
  }),
  activityTypes: PropTypes.array,
  className: PropTypes.string,
}
QuickBrowse.defaultProps = {
  list: "regions",
}
export default QuickBrowse
