import React, { useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import PropTypes from "prop-types"
import GTM from "@src/services/gtm"
import PrimaryLayout from "@src/layouts/primary"
import PageHelmet, { PageSeoPropTypes } from "@src/components/page-helmet"
import { normalizeTitle, generateMeta } from "@src/utils/page-data"
import HeaderSimple from "@src/components/page-header-simple.js"
import ResponsiveSection from "@src/components/container-responsive-section"
import ListColumnsContainer from "@src/components/core-list-columns"
import InputDropdown from "@src/components/input-dropdown"
import {
  getRegionLabel,
  getActivityTypeRouteCount,
} from "@src/components/cta-browse-route-guides"
import Link from "@src/components/core-link"
import { ImagePropTypes } from "@src/components/core-image"

const StyledResponsiveSection = styled(ResponsiveSection)`
  & > div:not(:first-child) {
    & > div:not(:last-child) {
      margin-bottom: 1em;
    }
  }
  & > div.country {
    margin-bottom: 2em;
  }
`
const StyledListColumnsContainer = styled(ListColumnsContainer)`
  & > li {
    font-size: 14px;
  }
`

const getActivityTypes = regions =>
  regions.reduce((activityTypes, region) => {
    if (
      region.activityTypeDetails &&
      region.activityTypeDetails?.nodes &&
      region.activityTypeDetails?.nodes.length
    ) {
      for (const activityTypeDetail of region.activityTypeDetails.nodes) {
        if (
          activityTypeDetail.activityType &&
          activityTypeDetail.activityType?.id &&
          !activityTypes.some(
            check => check.id === activityTypeDetail.activityType.id
          )
        ) {
          activityTypes.push(activityTypeDetail.activityType)
        }
      }
    }
    return activityTypes
  }, [])

const getRegionLink = (
  region,
  activityTypeId,
  showCount = true,
  linkVariant = "simple"
) => {
  const activityTypeDetail =
    activityTypeId &&
    region &&
    region.activityTypeDetails?.nodes?.find(
      check => check.activityType && check.activityType.id === activityTypeId
    )

  // use the URL from the region's activityTypeDetails or default to the region's primary activity URL
  const regionUrl =
    (activityTypeDetail && activityTypeDetail.uri) ||
    region.activityTypePrimary.uri
  return (
    <Link to={regionUrl} variant={linkVariant}>
      {showCount ? getRegionLabel(region, activityTypeId) : region.name}
    </Link>
  )
}

const RouteGuidesPage = ({ data }) => {
  const {
    tenAdventures: {
      page: {
        title,
        uri: pageUri,
        images: { header: headerImage },
        seo: pageSeo,
      },
      regions: { nodes: regions },
    },
  } = data
  const primarySlugs = ["canada", "usa", "united-kingdom"]
  const primaryRegions = regions.filter(
    check => check && check.slug && primarySlugs.includes(check.slug)
  )
  const globalRegions = regions.filter(
    check => check && check.slug && !primarySlugs.includes(check.slug)
  )
  const activityTypes = getActivityTypes(regions)
  const [activityType, setActivityType] = useState()

  const filterRegions = region =>
    !activityType ||
    !activityType.id ||
    (region.activityTypeDetails &&
      region.activityTypeDetails?.nodes &&
      region.activityTypeDetails?.nodes.some(
        check =>
          check.activityType && check.activityType?.id === activityType.id
      ))
  GTM.dataLayerPushPageInfo({
    template: "route-guides",
  })
  // setActivityTypes
  return (
    <PrimaryLayout>
      <PageHelmet
        title={normalizeTitle(pageSeo.title)}
        meta={generateMeta(pageUri, { ...pageSeo, metaRobotsNoindex: "index" })}
      />
      <HeaderSimple spacer="margin" image={headerImage}>
        <h1>{title}</h1>
      </HeaderSimple>
      <StyledResponsiveSection>
        <h2>Discover Your Next Adventure</h2>
        <InputDropdown
          onChange={({ data }) => setActivityType(data)}
          value={activityType || null}
          options={[{ text: "All Activities", value: null }].concat(
            activityTypes.map(activityType => ({
              text: activityType.name,
              value: activityType.id,
              data: activityType,
            }))
          )}
        />
        {primaryRegions.filter(filterRegions).map(country => (
          <div key={country.id} className="country">
            {/* Country Names */}
            <h3 className="fancy">
              {getRegionLink(country, activityType && activityType.id, false)}
            </h3>
            {country.children &&
              country.children?.nodes &&
              country.children?.nodes?.filter(filterRegions).map(state => {
                const routeCount = getActivityTypeRouteCount(
                  state.activityTypeDetails?.nodes || [],
                  activityType && activityType.id
                )

                return routeCount && state.activityTypeDetails ? (
                  // State/Province Names
                  <div key={state.id}>
                    <h4>
                      {getRegionLink(
                        state,
                        activityType && activityType.id,
                        false
                      )}
                    </h4>
                    {state.children &&
                      state.children?.nodes &&
                      state.children?.nodes.some(filterRegions) && (
                        <StyledListColumnsContainer
                          center={false}
                          items={state.children?.nodes.reduce(
                            (regions, region) => {
                              const routeCount = getActivityTypeRouteCount(
                                region.activityTypeDetails?.nodes || [],
                                activityType && activityType.id
                              )
                              if (
                                filterRegions(region) &&
                                region.activityTypeDetails &&
                                routeCount
                              ) {
                                regions.push({
                                  id: region.id,
                                  title: region.name,
                                  content: getRegionLink(
                                    region,
                                    activityType && activityType.id
                                  ),
                                })
                              }
                              return regions
                            },
                            []
                          )}
                        />
                      )}
                  </div>
                ) : null
              })}
          </div>
        ))}
      </StyledResponsiveSection>
      {globalRegions.some(filterRegions) && (
        <ResponsiveSection align="left">
          <h3 className="fancy">Worldwide</h3>
          <StyledListColumnsContainer
            center={false}
            items={globalRegions.reduce((regions, region) => {
              if (filterRegions(region)) {
                regions.push({
                  id: region.id,
                  title: region.name,
                  content: getRegionLink(
                    region,
                    activityType && activityType.id
                  ),
                })
              }
              return regions
            }, [])}
          />
        </ResponsiveSection>
      )}
    </PrimaryLayout>
  )
}

RouteGuidesPage.propTypes = {
  data: PropTypes.shape({
    tenAdventures: PropTypes.shape({
      page: PropTypes.shape({
        title: PropTypes.string,
        uri: PropTypes.string,
        images: PropTypes.shape({
          header: PropTypes.shape({ ...ImagePropTypes }),
        }),
        seo: PropTypes.shape({ ...PageSeoPropTypes }),
      }),
      regions: PropTypes.shape({
        nodes: PropTypes.arrayOf(PropTypes.object),
      }),
    }),
  }),
}
export default RouteGuidesPage

export const query = graphql`
  query RouteGuidesPageQuery($id: ID!) {
    tenAdventures {
      page(id: $id, idType: DATABASE_ID) {
        title
        uri
        images {
          header {
            ...TenAdventuresGQL_ImageSizesQuery
          }
        }
        seo {
          ...TenAdventuresGQL_PostSEO
        }
      }
      regions(first: 1000, where: { parent: null }) {
        nodes {
          id: databaseId
          name
          slug
          activityTypePrimary {
            uri
          }
          ...regionActivityTypes
          ...regionChildren
        }
      }
    }
  }
`
